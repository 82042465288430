import Joi from 'joi';

export interface LessonGoal {
  letters: string[];
  punctuations: string[];
  words: string[];
}

const lessonGoalSchema = Joi.object<LessonGoal>({
  letters: Joi.array().items(Joi.string()).required(),
  punctuations: Joi.array().items(Joi.string()).required(),
  words: Joi.array().items(Joi.string()).required(),
});

export interface LessonMeta {
  version: number;
  goal?: LessonGoal;
}

const lessonMetaSchema = Joi.object<LessonMeta>({
  version: Joi.number().integer().required(),
  goal: lessonGoalSchema.optional(),
});

export const EMPTY_LESSON_META: LessonMeta = { version: 1 };
export const EMPTY_LESSON_META_STRING = JSON.stringify(EMPTY_LESSON_META);

export abstract class LessonMetaManager {
  // private static copyMeta = (meta: LessonMeta): LessonMeta => ({ ...meta });

  static parseJsonToLessonMeta(json: string): LessonMeta {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedJson = JSON.parse(json);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error, value } = lessonMetaSchema.validate(parsedJson, { convert: false });
    if (error) {
      throw error;
    }

    return value;
  }
}
